<template>
  <v-container class="d-flex" style="height: calc(100vh - 145px)">
    <v-card class="ma-auto" max-width="360px">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12">
            <div>{{ $t("labels.old_password") }}:</div>
            <div>
              <v-text-field
                v-model="old_password"
                :placeholder="$t('labels.old_password')"
                type="password"
                autocomplete="off"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </div>
          </v-col>

          <v-col cols="12" sm="12">
            <div>{{ $t("labels.new_password") }}:</div>
            <div>
              <v-text-field
                :disabled="!old_password"
                v-model="new_password"
                :placeholder="$t('labels.new_password')"
                type="password"
                autocomplete="off"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </div>
          </v-col>

          <v-col cols="12" sm="12">
            <div>{{ $t("labels.confirm_password") }}:</div>
            <div>
              <v-text-field
                :disabled="!new_password || new_password.length < 6"
                v-model="confirm_new_password"
                :placeholder="$t('labels.confirm_password')"
                type="password"
                autocomplete="off"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </div>
          </v-col>

          <v-col cols="12" sm="12">
            <v-btn
              color="success"
              block
              :disabled="isDisabledBtn"
              @click="updatePassword"
              >{{ $t("labels.save") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "ChangePassword",
  data: () => ({
    isLoading: false,
    old_password: null,
    new_password: null,
    confirm_new_password: null,
  }),
  computed: {
    isDisabledBtn() {
      return (
        !this.old_password ||
        !this.new_password ||
        this.new_password.length < 6 ||
        this.new_password !== this.confirm_new_password
      );
    },
  },
  methods: {
    async updatePassword() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/update-password", {
          old_password: this.old_password,
          new_password: this.new_password,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_password_success"));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
